<template>
  <div class="login-bg">
    <div class="login-mian">
      <div class="main-top">
        <div class="top-little">
          <img src="../../assets/logo.png" />
          <div class="little-whtie">Stand Out Sky Webapp</div>
        </div>
        <el-form ref="form" :model="formData">
          <div class="main-lggg">
            <div class="main-lggg-name">用户名 Username</div>
            <el-input v-model="formData.uname"></el-input>
            <div class="main-lggg-name2">密码 Password</div>
            <el-input
              type="password"
              v-model="formData.password"
              v-on:keyup.enter.native="onSubmit()"
            ></el-input>
            <el-checkbox class="checks" v-model="checked"
              >记住账号密码</el-checkbox
            >
            <el-button class="but" type="primary" @click="onSubmit()"
              >立即登录</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
    <div class="wangji" v-show="false">
      <el-link class="wangjinamne" href="#" target="_blank"
        >忘记密码 Recover Password？</el-link
      >
    </div>
    <div class="company">
      2016-2021 © 立世云商 Stand Out Sky By
      <el-link
        class="wangjinamne"
        href="http://www.esun-cloud.com"
        target="_blank"
        >standsky.cn</el-link
      >
    </div>
    <video
      id="fullVideo"
      class=""
      muted="muted"
      autoplay="autoplay"
      loop="loop"
      src="../../static/images/cc.mp4"
    ></video>
  </div>

  <!--    <el-form
      ref="form"
      :model="formData"
      label-width="80px"
      style="width: 500px; margin: 200px auto"
    >
      <el-form-item label="用户名">
        <el-input v-model="formData.uname"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input
          type="password"
          v-model="formData.password"
          v-on:keyup.enter.native="onSubmit()"
        ></el-input>
      </el-form-item>
      <el-form-item style="text-align: right">
        <el-button type="primary" @click="onSubmit()" style="width: 100%"
          >确认登录</el-button
        >
      </el-form-item>
    </el-form> -->
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      formData: {
        uname: "",
        password: "",
      },
      checked: true,
    };
  },
  methods: {
    onSubmit() {
      this.myfun.request("adminUserLogin.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.$store.commit(
              "setAllAuthRuleModuleNameKeyList",
              result.data.allAuthRuleModuleNameKeyList
            ); //设置授权列表
            this.myfun.mySetStorage(
              "userInfo",
              result.data,
              result.data.tokenExpress,
              true
            );

            this.$router.push("/");
          }
        });
    },
  },
  created() {
    //退出登录
    this.myfun.myDelStorage("userInfo");
  },
};
</script>

<style>
video {
  position: fixed;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -999;
  top: 0;
}
.company {
  position: fixed;
  bottom: 1.875rem;
  width: 50%;
  margin-left: 50%;
  left: -25%;
  font-size: 0.75rem;
  color: #fff;
  text-align: center;
}
.wangjinamne:hover {
  color: #fff !important;
}
.wangjinamne {
  color: #fff !important;
}
.wangji {
  font-size: 0.875rem;
  padding: 1.25rem;
  margin: 0 auto;
  text-align: center;
}
.but {
  width: 35%;
  align-self: flex-end;
}
.checks {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.main-lggg-name2 {
  font-size: 0.875rem;
  padding-top: 1.6rem;
  padding-bottom: 0.625rem;
}
.main-lggg-name {
  font-size: 0.875rem;
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
}
.main-lggg {
  display: flex;
  padding: 1.25rem 1.5625rem;
  flex-direction: column;
}
.little-whtie {
  color: #fff;
  font-size: 0.875rem;
}
.top-little {
  display: flex;
  padding: 1.25rem 1.5625rem;
  align-items: center;
  justify-content: space-between;
}
.main-top {
  width: 20rem;
  height: 4.0625rem;
  background-color: #584475;
  background-image: linear-gradient(
    270deg,
    rgba(51, 148, 225, 0.18),
    transparent
  );
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.login-mian {
  width: 20rem;
  height: 25rem;
  margin: 12.5rem auto 0 auto;
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.login-bg {
  width: 100%;
  height: 100%;
  /* background: linear-gradient(-90deg, #263445 0%, #409eff 100%); */
  background-size: cover; /* 使图片平铺满整个浏览器（从宽和高的最大需求方面来满足，会使某些部分无法显示在区域中） */
  position: absolute; /* 不可缺少 */
  overflow: auto;
}
</style>
